import PropTypes from "prop-types";
import React from "react";

import PauseIconBlack from "@/assets/icons/pause-black.svg";
import PauseIconWhite from "@/assets/icons/pause-white.svg";
import PlayIconBlack from "@/assets/icons/play-black.svg";
import PlayIconWhite from "@/assets/icons/play-white.svg";
import withScreenDimensions from "@/utils/helpers/screen_dimensions";

import styles from "./GalleryPlayPauseToggle.module.scss";

const GalleryPlayPauseToggle = ({ playing, onClick, isTablet, isMobile }) => {
  const accessibilityLabel = playing ? "Pause video" : "Play video";
  const pause = isTablet || isMobile ? <PauseIconWhite /> : <PauseIconBlack />;
  const play = isTablet || isMobile ? <PlayIconWhite /> : <PlayIconBlack />;

  return (
    <button
      aria-label={accessibilityLabel}
      className={styles.galleryPlayToggle}
      name={`GalleryPlayPauseToggle - ${playing ? "Pause" : "Play"}`}
      onClick={onClick}
      title={accessibilityLabel}
    >
      {playing ? pause : play}
    </button>
  );
};

export default withScreenDimensions(GalleryPlayPauseToggle);

GalleryPlayPauseToggle.propTypes = {
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  onClick: PropTypes.func,
  playing: PropTypes.bool,
};
