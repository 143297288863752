import get from "lodash/get";
import PropTypes from "prop-types";
import React, { Component, createRef } from "react";

import withScreenDimensions from "@/utils/helpers/screen_dimensions";

import GalleryPlayPauseToggle from "../GalleryPlayPauseToggle";
import GalleryProgressBar from "../GalleryProgressBar";
import styles from "./GalleryButton.module.scss";

class GalleryButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      currentSlideIndex: props.currentSlideIndex,
      currentSlideRemainingPercentage: props.currentSlideRemainingPercentage,
    };

    this.descriptionContainer = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { active } = this.props;
    const previouslyActive = prevState.active;

    if (active && !previouslyActive) {
      this.setState({ active: true });
    } else if (previouslyActive && !active) {
      this.setState({ active: false });
    }
  }

  render() {
    const {
      index,
      currentSlideRemainingPercentage,
      slide,
      displayDescription,
      totalSlides,
      toggleVideoPlayFunc,
      userEngageFunc,
      goToSlideFunc,
      videoPlaying,
      isMobile,
      isTablet,
    } = this.props;
    const { active } = this.state;
    const itemStyles = {};

    if (isTablet || isMobile) {
      itemStyles.width = "100%";
      itemStyles.display = active ? "block" : "none";
    } else {
      const width = /*active ? "35%" :*/ `${100 / totalSlides + 1}%`;
      itemStyles.width = width;
    }

    const singleItemClass =
      totalSlides === 1 ? styles.galleryPagerItemSingle : "";

    const itemClasses = active
      ? `${styles.galleryPagerItem} ${styles.galleryPagerItemActive} ${singleItemClass}`
      : styles.galleryPagerItem;

    return (
      <div
        className={itemClasses}
        key={index}
        onClick={() => {
          goToSlideFunc(index);
        }}
        onKeyPress={({ which }) => {
          if (which !== 13) {
            return;
          }

          userEngageFunc();
          goToSlideFunc(index);
        }}
        role="button"
        style={itemStyles}
        tabIndex={0}
      >
        <div className={styles.galleryPagerItemInner}>
          <GalleryButtonContent
            active={active}
            displayDescription={displayDescription}
            goToSlideFunc={goToSlideFunc}
            index={index}
            playing={videoPlaying}
            slide={slide}
            toggleVideoPlayFunc={toggleVideoPlayFunc}
            userEngageFunc={userEngageFunc}
          />
          <div className={styles.galleryPagerItemProgress}>
            <GalleryProgressBar
              active={active}
              percent={currentSlideRemainingPercentage}
            />
          </div>
        </div>
        <div className={styles.galleryPagerItemBorderShimLeft} />
        <div className={styles.galleryPagerItemBorderShimRight} />
        <div className={styles.galleryPagerItemBackgroundShim} />
      </div>
    );
  }
}

GalleryButton.propTypes = {
  active: PropTypes.bool,
  currentSlideIndex: PropTypes.number,
  currentSlideRemainingPercentage: PropTypes.number,
  displayDescription: PropTypes.string,
  goToSlideFunc: PropTypes.func,
  index: PropTypes.number,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  slide: PropTypes.object,
  toggleVideoPlayFunc: PropTypes.func,
  totalSlides: PropTypes.number,
  userEngageFunc: PropTypes.func,
  videoPlaying: PropTypes.bool,
};

export default withScreenDimensions(GalleryButton);

const GalleryButtonContent = (props) => {
  const descriptionWrap = createRef();
  const {
    index,
    slide,
    active,
    displayDescription = "off",
    toggleVideoPlayFunc,
    userEngageFunc,
    playing,
    goToSlideFunc,
  } = props;

  const count = index + 1;
  const displayCount = count <= 9 ? `0${count}` : count;
  const slideIsVideo = slide.fields.mediaType === "Video";
  const buttonHasCopy = get(slide, "fields.description", "") !== "";

  return (
    <>
      {slideIsVideo ? (
        <div className={styles.galleryPagerItemPlayToggleWrap}>
          <GalleryPlayPauseToggle
            onClick={toggleVideoPlayFunc}
            playing={playing}
          />
          <div
            onClick={() => {
              goToSlideFunc(index);
              userEngageFunc();
            }}
          >
            <span>{slide.fields.title}</span>
          </div>
          {displayDescription === "On" && buttonHasCopy && (
            <div
              className={styles.galleryPagerItemDescriptionWrap}
              ref={descriptionWrap}
              style={{
                opacity: active ? 1 : 0,
              }}
            >
              <p>{slide.fields.description}</p>
            </div>
          )}
        </div>
      ) : (
        <div
          onClick={() => {
            goToSlideFunc(index);
            userEngageFunc();
          }}
        >
          <div className={styles.galleryPagerItemNumber}>{displayCount}</div>
          <div className={styles.galleryPagerItemTitle}>
            <span>{slide.fields.title}</span>
          </div>
          {displayDescription === "On" && buttonHasCopy && (
            <div
              className={styles.galleryPagerItemDescriptionWrap}
              ref={descriptionWrap}
              style={{
                opacity: active ? 1 : 0,
              }}
            >
              <p>{slide.fields.description}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

GalleryButtonContent.propTypes = {
  active: PropTypes.bool,
  displayDescription: PropTypes.string,
  goToSlideFunc: PropTypes.func,
  index: PropTypes.number,
  playing: PropTypes.bool,
  slide: PropTypes.object,
  toggleVideoPlayFunc: PropTypes.func,
  userEngageFunc: PropTypes.func,
};
