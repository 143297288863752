import get from "lodash/get";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import slugify from "slugify";

import EditorialTitle from "@/components/EditorialTitle";
import ErrorHandler from "@/components/ErrorHandler";
import { getStyleObject } from "@/utils/helpers/module_style_tags";
import leftCropTransition from "@/utils/transitions/left-crop-gallery.module.scss";
// Caesar and I worked on making this a mixin and I've been unable to recreate that
// in this branch. So the two includes below are FPO until the mix-in issue is fixed.
import rightCropTransition from "@/utils/transitions/right-crop-gallery.module.scss";

import GalleryButton from "../GalleryButton";
import GalleryMedia from "../GalleryMedia";
import GalleryProgressBar from "../GalleryProgressBar";
import styles from "./Gallery.module.scss";

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMediaType: null,
      currentSlideIndex: 0,
      currentSlideRemainingPercentage: 0,
      currentSlideTimeRemainingMs: 5500,
      entered: false,
      noItems: false,
      showControls: true,
      singleItem: false,
      slideTimeDurationMs: 5500,
      timerActive: true,
      transitionDirection: rightCropTransition,
      transitioning: false,
      videoPlaying: false,
    };

    this.section = null;
    this.pagerWrap = null;
    this.pagerWrapTimer = null;
    this.timer = null;
    this.startGallery = this.startGallery.bind(this);
    this.resetGallery = this.resetGallery.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.userDisableTimer = this.userDisableTimer.bind(this);
    this.timerTick = this.timerTick.bind(this);
    this.goToSlide = this.goToSlide.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.prevSlide = this.prevSlide.bind(this);
    this.toggleVideoPlay = this.toggleVideoPlay.bind(this);
  }

  componentDidMount() {
    const { data } = this.props;
    const { gallerySlides } = data;

    if (gallerySlides.length === 0) {
      this.setState({ noItems: true });

      return false;
    }

    this.setState({
      currentMediaType:
        gallerySlides[0].fields.mediaType === "Video" ? "video" : "image",
    });

    this.startGallery();
  }
  componentWillUnmount() {
    this.stopTimer();
  }

  startGallery() {
    const { data } = this.props;
    const { gallerySlides } = data;
    const slideIsVideo = gallerySlides[0].fields.mediaType === "Video";

    this.setState(
      {
        entered: true,
        paused: false,
        showControls: true,
        videoPlaying: slideIsVideo,
      },
      () => {
        this.startTimer();
      }
    );
  }

  resetGallery() {
    this.userDisableTimer();
    this.goToSlide(0);
  }

  // TIMER FUNCTIONALITY:
  // Timer Start
  startTimer() {
    if (this.timer) {
      return;
    }

    this.setState({ timerActive: true }, () => {
      this.timer = setInterval(this.timerTick, 50);
    });
  }

  // Timer Stop
  stopTimer() {
    if (!this.timer) {
      return;
    }

    window.clearInterval(this.timer);

    this.setState({ paused: true, timerActive: false }, () => {
      this.timer = null;
    });
  }

  // User Engagement Function to Disable Timer
  userDisableTimer() {
    const { timerActive } = this.state;

    if (!timerActive) {
      return;
    }

    this.stopTimer();

    this.setState({
      currentSlideRemainingPercentage: null,
      currentSlideTimeRemainingMs: 5500,
      timerActive: false,
    });
  }

  // Timer Tick Function
  // Accepts progress variable from active video asset (if active)
  // Based on active content the progress bar is set from either
  // timer or video current playhead.
  timerTick(progress) {
    const { currentMediaType, slideTimeDurationMs, transitioning, paused } =
      this.state;
    let { currentSlideTimeRemainingMs } = this.state;
    let currentSlideRemainingPercentage;
    let played;

    if (transitioning || paused) {
      return;
    }

    switch (currentMediaType) {
      case "video":
        if (progress) {
          played = progress.played;

          this.setState(
            { currentSlideRemainingPercentage: played.toFixed(10) * 100 },
            () => {
              if (played === 1) {
                this.nextSlide();
              }
            }
          );
        }

        break;
      case "image":
        if (currentSlideTimeRemainingMs === 0) {
          this.nextSlide();
          currentSlideTimeRemainingMs = slideTimeDurationMs;
        } else {
          currentSlideTimeRemainingMs = currentSlideTimeRemainingMs - 50;
        }

        currentSlideRemainingPercentage =
          (Math.abs(slideTimeDurationMs - currentSlideTimeRemainingMs) /
            slideTimeDurationMs) *
          100;

        this.setState({
          currentSlideRemainingPercentage,
          currentSlideTimeRemainingMs,
        });

        break;
    }
  }

  // VIDEO FUNCTIONALITY:
  // Toggle Video Playback
  toggleVideoPlay() {
    const { videoPlaying } = this.state;
    this.setState({ videoPlaying: !videoPlaying });
  }

  // SLIDESHOW FUNCTIONALITY:
  // Sets Active Slide
  // Used by nextSlide() and prevSlide()
  goToSlide(newIndex) {
    const { slideTimeDurationMs, currentSlideIndex, timerActive } = this.state;
    const { data } = this.props;
    const { gallerySlides } = data;
    const newSlide = gallerySlides[newIndex];
    const currentSlideIsVideo =
      gallerySlides[currentSlideIndex].fields.mediaType === "Video";
    const newSlideIsVideo = newSlide.fields.mediaType === "Video";
    const direction =
      newIndex > currentSlideIndex ? rightCropTransition : leftCropTransition;

    if (!newSlide || currentSlideIndex === newIndex) {
      return;
    }

    // Lock Pager Wrap Height
    if (this.pagerWrap) {
      window.clearTimeout(this.pagerWrapTimer);
      this.pagerWrap.style.height = `${this.pagerWrap.offsetHeight}px`;
    }

    this.setState(
      {
        timerActive: false,
        transitionDirection: direction,
        transitioning: true,
      },
      () => {
        // Pre-Slide Transition:
        let nextSlideDelay = 0;

        if (currentSlideIsVideo) {
          this.setState({ videoPlaying: false });
          nextSlideDelay = 100;
        }

        setTimeout(() => {
          this.setState(
            {
              currentMediaType: newSlideIsVideo ? "video" : "image",
              currentSlideIndex: newIndex,
              currentSlideTimeRemainingMs: slideTimeDurationMs,
              transitioning: false,
            },
            () => {
              // Post-Slide Transition (but NOT after animation):
              if (newSlideIsVideo) {
                this.setState({ videoPlaying: true });
              }

              if (!newSlideIsVideo && timerActive) {
                this.startTimer();
              } else if (!timerActive) {
                this.setState({ currentSlideRemainingPercentage: null });
              }

              // Unlock Pager Wrap Height after 1200ms
              this.pagerWrapTimer = setTimeout(() => {
                if (!this.pagerWrap) {
                  return;
                }

                if (this.pagerWrap) {
                  this.pagerWrap.style.height = `auto`;
                }
              }, 1200);
            }
          );
        }, nextSlideDelay);
      }
    );
  }

  // Increments Slides Forward
  nextSlide() {
    const { currentSlideIndex } = this.state;
    const { data } = this.props;
    const { gallerySlides } = data;
    const newSlide =
      currentSlideIndex === gallerySlides.length - 1
        ? 0
        : currentSlideIndex + 1;
    this.goToSlide(newSlide);
  }

  // Increments Slides Backward
  prevSlide() {
    const { currentSlideIndex } = this.state;
    const { data } = this.props;
    const { gallerySlides } = data;
    const newSlide =
      currentSlideIndex === 0
        ? gallerySlides.length - 1
        : currentSlideIndex - 1;
    this.goToSlide(newSlide);
  }

  render() {
    try {
      const {
        currentSlideIndex,
        currentSlideRemainingPercentage,
        showControls,
        transitionDirection,
        videoPlaying,
        noItems,
      } = this.state;
      const { id, data } = this.props;
      const {
        gallerySlides,
        displayDescription,
        editorialTitle,
        anchorTag,
        marginBottom,
      } = data;
      const sectionSlug = slugify(get(data, "subNavLabel", id)).toLowerCase();

      if (noItems) {
        return null;
      }

      /* Controls Gallery Tour Navigation Pager */
      const pagerClasses = showControls
        ? `${styles.galleryPagerWrap} ${styles.galleryPagerWrapActive}`
        : `${styles.galleryPagerWrap}`;

      return (
        <section
          className={`${styles.gallery} module`}
          data-attr-module-name="module-gallery"
          data-attr-scroll={anchorTag}
          data-is="Gallery"
          id={sectionSlug}
          ref={(element) => {
            this.section = element;
          }}
          style={getStyleObject({ marginBottom })}
        >
          {/* Gallery Slides for both Desktop & Mobile  */}
          <div className={styles.gallerySlidesWrap}>
            {editorialTitle && (
              <div className={`${styles.gallerySlidesEditorialTitle}`}>
                <div className="container">
                  <div className={`col-lg-7 col-md-16`}>
                    <EditorialTitle data={editorialTitle} />
                  </div>
                </div>
              </div>
            )}

            <TransitionGroup className={styles.gallerySlides} component="div">
              {gallerySlides &&
                gallerySlides.map((slide, index) => {
                  if (index !== currentSlideIndex) {
                    return null;
                  }

                  return (
                    <CSSTransition
                      classNames={transitionDirection}
                      in
                      key={slide?.fields?.media?.fields?.file?.url}
                      timeout={1000}
                    >
                      <GalleryMedia
                        navigateBackFunc={this.prevSlide}
                        navigateForwardFunc={this.nextSlide}
                        progressFunc={this.timerTick}
                        progressInterval={50}
                        slide={slide}
                        userEngageFunc={() => {}}
                        videoPlaying={videoPlaying}
                      />
                    </CSSTransition>
                  );
                })}
            </TransitionGroup>

            {/* Progress Indicators for Mobile Only */}
            <div className={styles.galleryPagerMobile}>
              <div className={`${styles.galleryPagerMobileList}`}>
                {gallerySlides &&
                  gallerySlides.map((slide, index) => {
                    const active = currentSlideIndex === index;

                    return (
                      <GalleryProgressBar
                        active={active}
                        index={index}
                        key={slide?.fields?.media?.fields?.file?.url}
                        percent={currentSlideRemainingPercentage}
                      />
                    );
                  })}
              </div>
            </div>
          </div>

          {/* Gallery Navigation Buttons for Desktop & Mobile */}
          <div className={`${pagerClasses}`}>
            <div
              className={`${styles.galleryPager} container no-gutter`}
              ref={(element) => {
                this.pagerWrap = element;
              }}
            >
              {gallerySlides &&
                gallerySlides.map((slide, index) => {
                  const { currentSlideRemainingPercentage, currentSlideIndex } =
                    this.state;

                  return (
                    <GalleryButton
                      active={index === currentSlideIndex}
                      currentSlideIndex={currentSlideIndex}
                      currentSlideRemainingPercentage={
                        currentSlideRemainingPercentage
                      }
                      displayDescription={displayDescription}
                      goToSlideFunc={this.goToSlide}
                      index={index}
                      key={slide?.fields?.media?.fields?.file?.url}
                      slide={slide}
                      toggleVideoPlayFunc={this.toggleVideoPlay}
                      totalSlides={gallerySlides.length}
                      userEngageFunc={() => {}}
                      videoPlaying={videoPlaying}
                    />
                  );
                })}
            </div>

            <div className={styles.galleryPagerProgressShim} />
          </div>
        </section>
      );
    } catch (error) {
      return <ErrorHandler componentName="GalleryModule" error={error} />;
    }
  }
}

Gallery.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
};

export default Gallery;
