import PropTypes from "prop-types";
import React from "react";

import styles from "./GalleryProgressBar.module.scss";

const GalleryProgressBar = ({ active, percent }) => {
  const timeCompletedPercent =
    percent !== null ? `calc(${percent}% + 1px)` : `calc(0%)`;
  const progressBarStyles = active
    ? { width: timeCompletedPercent }
    : { width: `calc(0% + 0px)` };

  return (
    <div className={styles.galleryProgressBar}>
      <span style={progressBarStyles} />
    </div>
  );
};

export default GalleryProgressBar;

GalleryProgressBar.propTypes = {
  active: PropTypes.bool,
  percent: PropTypes.number,
};
