import PropTypes from "prop-types";
import React from "react";
import ReactPlayer from "react-player";
import { useSwipeable } from "react-swipeable";

import ResponsiveImage from "@/components/ResponsiveImage";
import ScreenDimensions from "@/utils/helpers/screen_dimensions";

import styles from "./GalleryMedia.module.scss";

const GalleryMedia = ({
  isMobile,
  navigateBackFunc,
  navigateForwardFunc,
  progressFunc,
  progressInterval,
  slide,
  userEngageFunc,
  videoPlaying,
}) => {
  const handlers = useSwipeable({
    onSwiped: (e) => {
      const { dir } = e;

      // Pause timer on user engage
      userEngageFunc();

      // Is user clicking left or right hemisphere?
      if (dir === "Left" /*currentTarget.offsetWidth / 2 < clientX*/) {
        navigateForwardFunc();
      } else {
        navigateBackFunc();
      }
    },
  });

  const getMediaElement = () => {
    const { media } = slide.fields;
    const { file } = media.fields;

    const fileMobile = slide?.fields.mediaMobile.fields;
    const altTextDesktop = slide?.fields.media.fields.description || "";
    const altTextMobile = slide?.fields.mediaMobile.fields.description || "";
    const slideIsVideo = slide.fields.mediaType === "Video";
    const videoUrl = isMobile
      ? `https:${fileMobile.file.url}`
      : `https:${file.url}`;

    if (slideIsVideo) {
      return (
        <ReactPlayer
          height="100%"
          muted={true}
          onProgress={progressFunc}
          playing={videoPlaying}
          playsinline={true}
          progressInterval={progressInterval}
          url={(videoUrl.indexOf("//") === 0 ? "https:" : "") + videoUrl}
          width="100%"
        />
      );
    }

    return (
      <ResponsiveImage
        altText={altTextMobile || altTextDesktop}
        defaultImage={slide?.fields?.mediaMobile}
        lazy={false}
        mdImage={slide?.fields?.media}
      />
    );
  };

  return (
    <div className={styles.galleryMedia} {...handlers}>
      {getMediaElement()}
    </div>
  );
};

GalleryMedia.propTypes = {
  isMobile: PropTypes.bool,
  navigateBackFunc: PropTypes.func,
  navigateForwardFunc: PropTypes.func,
  progressFunc: PropTypes.func,
  progressInterval: PropTypes.number,
  slide: PropTypes.object.isRequired,
  userEngageFunc: PropTypes.func,
  videoPlaying: PropTypes.bool,
};

export default ScreenDimensions(GalleryMedia);
